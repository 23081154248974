import { getHeadspaceGlobalEnvironment } from '@headspace/build-scripts';

export interface EnvironmentFields {
  API_HOST?: string;
  AUTH0_AUDIENCE: string;
  AUTH_COOKIE?: string;
  DOT_COM_HOST?: string;
  DSN?: string;
  GTM_ID?: string;
  NODE_ENV?: string;
  SELF_HOST?: string;
  STATSIG_SDK_KEY?: string;
  WEB_APP_HOST?: string;
}

const environmentVariables = getHeadspaceGlobalEnvironment<EnvironmentFields>();

export default {
  apiHost: environmentVariables.API_HOST,
  auth0Audience: environmentVariables.AUTH0_AUDIENCE,
  authCookie: environmentVariables.AUTH_COOKIE,
  dotCom: environmentVariables.DOT_COM_HOST,
  gtmId: environmentVariables.GTM_ID,
  nodeEnv: environmentVariables.NODE_ENV,
  self: environmentVariables.SELF_HOST,
  sentryDSN: environmentVariables.DSN,
  statsigSdkKey: environmentVariables.STATSIG_SDK_KEY,
  webApp: environmentVariables.WEB_APP_HOST,
};
