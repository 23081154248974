import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DataPage } from './DataPage/DataPage';
import { ConsentFlow } from './ConsentFlow/ConsentFlow';
import i18next from 'i18next';

export interface MainProps {
  t: i18next.TFunction;
}

export const Main = (props: MainProps) => (
  <main>
    <Switch>
      <Route exact={true} path="/" component={ConsentFlow} />
      <Route path="/data" component={DataPage} />
    </Switch>
  </main>
);
