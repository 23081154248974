import * as React from 'react';
import {
  Consent,
  ConsentCodes,
  EnactedConsent,
  EnactedConsentHash,
} from '../../redux/modules/consents/consents';
import { FieldWrapper } from '../FieldWrapper';
import { language } from '../../i18n';
import i18next from 'i18next';

interface Props {
  options: string[];
  allUserConsents: Consent[];
  enactedUserConsents: EnactedConsent[];
  putUserConsent: (status: string, consentId: number) => void;
  t: i18next.TFunction;
  language: language;
}

export class ConsentsSection extends React.Component<Props> {
  mapEnactedConsents(): EnactedConsentHash {
    const { enactedUserConsents } = this.props;
    return enactedUserConsents.reduce(
      (hash: EnactedConsentHash, consent: EnactedConsent) => {
        hash[consent.attributes.consentId] = consent.attributes.status;
        return hash;
      },
      {},
    );
  }

  public render() {
    const {
      t,
      language,
      allUserConsents,
      options,
      putUserConsent,
      enactedUserConsents,
      ...props
    } = this.props;
    const enactedConsentHash = this.mapEnactedConsents();

    // excluding affirmative consent from the permissions tab
    const filteredAllUserConsents = allUserConsents.filter(
      obj => !(obj?.attributes?.code === ConsentCodes.AFFIRMATIVE_CONSENT_CODE),
    );

    return (
      <div {...props}>
        {filteredAllUserConsents.map((consent, i) => {
          // overrides copy we get from the api to keep text and translations in one place
          consent.attributes.title = t(
            `consent.${consent.attributes.code}.title`,
          );
          consent.attributes.body = t(
            `consent.${consent.attributes.code}.body`,
          );

          return (
            <FieldWrapper
              key={`${consent.attributes.title}-${i}`}
              enactedConsentHash={enactedConsentHash}
              options={options}
              putUserConsent={putUserConsent}
              {...consent}
            />
          );
        })}
      </div>
    );
  }
}
