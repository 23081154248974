import * as React from 'react';
import {
  tab,
  tabActive,
  tabBar,
  tabBarUnderline,
  tabList,
  tabUnderline,
  tabUnderlineActive,
  tabUnderlineInactive,
} from './styles';

export interface TabInfo {
  label: string;
  url: string;
}

export interface TabBarProps {
  tabs: TabInfo[];
  tabUpdateAction: (tab: TabInfo) => void;
  defaultActiveTab?: number;
}

export interface TabBarState {
  activeIndex: number;
}

export class TabBar extends React.Component<TabBarProps, TabBarState> {
  constructor(props: TabBarProps) {
    super(props);
    this.state = {
      activeIndex: props.defaultActiveTab || 0,
    };
  }

  setActiveTab = (idx: number): void => {
    if (idx === -1) {
      console.trace('setActiveTab called with -1');
      return;
    }
    this.setState({ activeIndex: idx });
  };

  generateTabProps = (tab: TabInfo, idx: number) => {
    const isActive = idx === this.state.activeIndex;

    const onTabClick = () => {
      this.setActiveTab(idx);
      this.props.tabUpdateAction(tab);
    };
    return {
      isActive,
      onTabClick,
    };
  };

  generateTabs = (
    tabInfo: TabInfo[],
  ): { tabs: JSX.Element[]; tabUnderlines: JSX.Element[] } => {
    const tabs: JSX.Element[] = [];
    const tabUnderlines: JSX.Element[] = [];
    tabInfo.forEach((tab, idx) => {
      const tabProps = this.generateTabProps(tab, idx);
      tabs.push(
        <Tab
          tabInfo={tab}
          key={idx}
          onClick={tabProps.onTabClick}
          isActive={tabProps.isActive}
        />,
      );
      const tabUnderlineStyle = tabProps.isActive
        ? { ...tabUnderline, ...tabUnderlineActive }
        : { ...tabUnderline, ...tabUnderlineInactive };
      tabUnderlines.push(
        <div
          {...tabUnderlineStyle}
          key={tab.label}
          onClick={tabProps.onTabClick}
        />,
      );
    });
    return {
      tabs,
      tabUnderlines,
    };
  };

  render(): JSX.Element {
    const tabs = this.generateTabs(this.props.tabs);
    return (
      <div>
        <div {...tabBar} className="tabBar">
          <ul {...tabList}>{tabs.tabs}</ul>
        </div>
        <div {...tabBarUnderline}>{tabs.tabUnderlines}</div>
      </div>
    );
  }
}

interface TabProps {
  tabInfo: TabInfo;
  onClick: Function;
  isActive: boolean;
}

export const Tab = (props: TabProps) => {
  const tabStyle = props.isActive ? { ...tab, ...tabActive } : { ...tab };
  return (
    <li {...tabStyle} className="tab" onClick={props.onClick}>
      {props.tabInfo.label}
    </li>
  );
};
