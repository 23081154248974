import { Action, Reducer } from 'redux';
import { ReactNode } from 'react';

export enum constants {
  CHECK_CONSENTS_STATUS = 'consents/CHECK_CONSENTS_STATUS',
  CHECK_CONSENTS_STATUS_SUCCESS = 'consents/CHECK_CONSENTS_STATUS_SUCCESS',
  GET_USER_CONSENTS = 'consents/GET_USER_CONSENTS',
  GET_V1_USER_CONSENTS = 'consents/GET_V1_USER_CONSENTS',
  GET_USER_CONSENTS_SUCCESS = 'consents/GET_USER_CONSENTS_SUCCESS',
  GET_V1_USER_CONSENTS_SUCCESS = 'consents/GET_V1_USER_CONSENTS_SUCCESS',
  GET_ENACTED_USER_CONSENTS = 'consents/GET_ENACTED_USER_CONSENTS',
  GET_ENACTED_USER_CONSENTS_SUCCESS = 'consents/GET_ENACTED_USER_CONSENTS_SUCCESS',
  GET_ALL_USER_CONSENTS = 'consents/GET_ALL_USER_CONSENTS',
  GET_ALL_USER_CONSENTS_SUCCESS = 'consents/GET_ALL_USER_CONSENTS_SUCCESS',
  PUT_USER_CONSENT = 'consents/PUT_USER_CONSENT',
  PUT_USER_CONSENT_SUCCESS = 'consents/PUT_USER_CONSENT_SUCCESS',
}

export enum ConsentCodes {
  AFFIRMATIVE_CONSENT_CODE = 'AFFIRMATIVE_CONSENT',
  EMAIL_RETARGETING_CODE = 'EMAIL_RETARGETING',
  EX_SUBSCRIBER_DIRECT_CODE = 'EX_SUBSCRIBER_DIRECT',
  '3RD_PARTY_DIRECT_CODE' = '3RD_PARTY_DIRECT',
}

export enum ConsentScopes {
  INDIVIDUAL = 'INDIVIDUAL',
  UNIVERSAL = 'UNIVERSAL',
}

export interface Consent {
  id: number;
  type: string;
  attributes: ConsentAttributes;
}

export interface ConsentAttributes {
  mediaItemId: number;
  description: string;
  title: string;
  body: ReactNode;
  code: ConsentCodes;
  name: string;
  version: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  scope: ConsentScopes;
  permissions: string[];
}

export type IndividualConsent = {
  id: number;
} & ConsentAttributes;

export type EnactedIndividualConsent = EnactedConsentAttributes;

export interface EnactedConsent {
  id: number;
  attributes: EnactedConsentAttributes;
}

export interface EnactedConsentAttributes {
  userId: string;
  consentId: number;
  status: ConsentStatus;
}

export enum ConsentStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface EnactedConsentHash {
  [consentId: number]: ConsentStatus;
}

export interface CheckConsentsStatusAction {
  type: constants.CHECK_CONSENTS_STATUS;
}

export interface CheckConsentsStatusSuccessAction {
  type: constants.CHECK_CONSENTS_STATUS_SUCCESS;
  needsConsentUpdate: boolean;
}

export interface GetUserConsentsAction {
  type: constants.GET_USER_CONSENTS;
}

export interface GetV1UserConsentsAction {
  type: constants.GET_V1_USER_CONSENTS;
}

export interface GetUserConsentsSuccessAction {
  type: constants.GET_USER_CONSENTS_SUCCESS;
  consents: Consent[];
}

export interface GetV1UserConsentsSuccessAction {
  type: constants.GET_V1_USER_CONSENTS_SUCCESS;
  userConsents: EnactedIndividualConsent[];
  consents: IndividualConsent[];
}

export interface GetEnactedUserConsentsAction {
  type: constants.GET_ENACTED_USER_CONSENTS;
}

export interface GetEnactedUserConsentsSuccessAction {
  type: constants.GET_ENACTED_USER_CONSENTS_SUCCESS;
  consents: Consent[];
}

export interface GetAllUserConsentsAction {
  type: constants.GET_ALL_USER_CONSENTS;
}

export interface GetAllUserConsentsSuccessAction {
  type: constants.GET_ALL_USER_CONSENTS_SUCCESS;
  consents: Consent[];
}

export interface PutUserConsentAction {
  type: constants.PUT_USER_CONSENT;
  status: string;
  consentId: number;
  successDispatch?: Action;
}

export interface PutUserConsentSuccessAction {
  type: constants.PUT_USER_CONSENT_SUCCESS;
}

export namespace ConsentsActions {
  export const checkConsentsStatus = (): CheckConsentsStatusAction => ({
    type: constants.CHECK_CONSENTS_STATUS,
  });

  export const checkConsentsStatusSuccess = (
    needsConsentUpdate: boolean,
  ): CheckConsentsStatusSuccessAction => ({
    needsConsentUpdate,
    type: constants.CHECK_CONSENTS_STATUS_SUCCESS,
  });

  export const getUserConsents = (): GetUserConsentsAction => ({
    type: constants.GET_USER_CONSENTS,
  });

  export const getUserConsentsSuccess = (
    consents: Consent[],
  ): GetUserConsentsSuccessAction => ({
    consents,
    type: constants.GET_USER_CONSENTS_SUCCESS,
  });

  export const getV1UserConsents = () => ({
    type: constants.GET_V1_USER_CONSENTS,
  });

  export const getV1UserConsentsSuccess = (
    userConsents: EnactedIndividualConsent[],
    consents: IndividualConsent[],
  ): GetV1UserConsentsSuccessAction => ({
    userConsents,
    consents,
    type: constants.GET_V1_USER_CONSENTS_SUCCESS,
  });

  export const getEnactedUserConsents = (): GetEnactedUserConsentsAction => ({
    type: constants.GET_ENACTED_USER_CONSENTS,
  });

  export const getEnactedUserConsentsSuccess = (
    consents: Consent[],
  ): GetEnactedUserConsentsSuccessAction => ({
    consents,
    type: constants.GET_ENACTED_USER_CONSENTS_SUCCESS,
  });

  export const getAllUserConsents = (): GetAllUserConsentsAction => ({
    type: constants.GET_ALL_USER_CONSENTS,
  });

  export const getAllUserConsentsSuccess = (
    consents: Consent[],
  ): GetAllUserConsentsSuccessAction => ({
    consents,
    type: constants.GET_ALL_USER_CONSENTS_SUCCESS,
  });

  export const putUserConsent = (
    status: string,
    consentId: number,
    successDispatch?: Action,
  ): PutUserConsentAction => ({
    status,
    consentId,
    successDispatch,
    type: constants.PUT_USER_CONSENT,
  });

  export const putUserConsentSuccess = (): PutUserConsentSuccessAction => ({
    type: constants.PUT_USER_CONSENT_SUCCESS,
  });
}

export const defaultState = {
  userConsents: [],
  enactedUserConsents: [],
  allUserConsents: [],
  individualConsents: [],
  enactedIndividualConsents: [],
  needsConsentLoading: false,
  needsConsentUpdate: false,
  putConsentLoading: false,
};

export interface ConsentsState {
  userConsents: Consent[];
  enactedUserConsents: EnactedConsent[];
  allUserConsents: Consent[];
  enactedIndividualConsents: EnactedIndividualConsent[];
  individualConsents: IndividualConsent[];
  needsConsentLoading: boolean;
  needsConsentUpdate: boolean;
  putConsentLoading: boolean;
}

export const reducer: Reducer<ConsentsState> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case constants.CHECK_CONSENTS_STATUS:
      return { ...state, needsConsentLoading: true };
    case constants.CHECK_CONSENTS_STATUS_SUCCESS:
      return {
        ...state,
        needsConsentLoading: false,
        needsConsentUpdate: action.needsConsentUpdate,
      };
    case constants.GET_USER_CONSENTS_SUCCESS:
      return {
        ...state,
        userConsents: action.consents,
      };
    case constants.GET_V1_USER_CONSENTS_SUCCESS:
      return {
        ...state,
        enactedIndividualConsents: action.userConsents,
        individualConsents: action.consents,
      };
    case constants.GET_ENACTED_USER_CONSENTS_SUCCESS:
      return {
        ...state,
        enactedUserConsents: action.consents,
      };
    case constants.GET_ALL_USER_CONSENTS_SUCCESS:
      return {
        ...state,
        allUserConsents: action.consents,
      };
    case constants.PUT_USER_CONSENT:
      return { ...state, putConsentLoading: true };
    case constants.PUT_USER_CONSENT_SUCCESS:
      return { ...state, putConsentLoading: false };
    case constants.GET_USER_CONSENTS:
    case constants.GET_ENACTED_USER_CONSENTS:
    case constants.GET_ALL_USER_CONSENTS:
    default:
      return state;
  }
};
