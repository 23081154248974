import { StatsigOptions, StatsigUser } from 'statsig-react';
import env from '../config/env';

interface StatsigConfig {
  options: StatsigOptions;
  sdkKey: string;
  user: StatsigUser;
  waitForInitialization: boolean;
}

export const statsigOptions = {
  environment: {
    // there is no 'integration' environment in statsig at this time.
    tier: env.nodeEnv === 'integration' ? 'development' : env.nodeEnv,
  },
};

export const createStatsigConfig = (): StatsigConfig => {
  return {
    options: {
      ...statsigOptions,
    },
    sdkKey: env.statsigSdkKey,
    user: {},
    waitForInitialization: true,
  };
};
