// find more at https://github.com/HeadspaceMeditation/hs-snowplow-schemas
const SCHEMA_LIST: { [key: string]: { [key: string]: string } } = {
  activity: {
    name: 'activity_context',
    schema: 'iglu:com.headspace/activity_context/jsonschema/1-0-0',
  },
  media: {
    name: 'media_context',
    schema: 'iglu:com.headspace/media_context/jsonschema/1-0-0',
  },
  screenView: {
    name: 'screen_view_context',
    schema: 'iglu:com.headspace/screen_view_context/jsonschema/1-0-0',
  },
  userStats: {
    name: 'user_stats_context',
    schema: 'iglu:com.headspace/user_stats_context/jsonschema/1-0-0',
  },
};

export interface SP_Context {
  [key: string]: {
    schema: string;
    name: string;
  };
}

export const _serializeContextData = (data: any) => {
  return Object.keys(data)
    .filter(x => data[x]) // remove null or undefined
    .reduce((accum, key) => {
      let result;
      const type = typeof data[key];
      switch (type) {
        case 'boolean':
          result = {
            ...accum,
            [key]: data[key],
          };
          break;
        default:
          result = {
            ...accum,
            [key]: data[key].toString(),
          };
          break;
      }
      return result;
    }, {});
};

export const _log = (...args: any[]) => {
  if (window.__sp_tracker_debug) {
    console.debug(...args); // eslint-disable-line
  }
};

export const _flattenContext = (contexts?: SP_Context | SP_Context[]) => {
  let wrappedContext;
  let flattenedContext;

  if (contexts) {
    if (!Array.isArray(contexts)) {
      wrappedContext = [contexts];
    } else {
      wrappedContext = contexts;
    }
    flattenedContext = {};
    wrappedContext.forEach(({ schema, data }) => {
      Object.keys(data)
        .filter(x => data[x])
        .forEach(key => {
          flattenedContext[`${SCHEMA_LIST[schema].name}.${key}`] = data[key];
        });
    });
  }

  return flattenedContext;
};

export const trackSE = (
  category: string,
  action?: string,
  label?: string,
  property?: string,
  value?: string,
  contexts?: SP_Context | SP_Context[],
) => {
  const dataLayer = window.dataLayer;
  const flattenedContext = _flattenContext(contexts);
  if (!dataLayer) return;
  _log(
    'Track structure event:',
    category,
    action,
    label,
    property,
    value,
    flattenedContext,
  );
  dataLayer.push({
    event: 'customSnowplowEvent',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventProperty: property,
    eventValue: value,
    eventContext: flattenedContext,
  });
};
