import { css } from '@headspace/hs-web-style-guide';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import { slateGrey2 } from '@headspace/hs-web-style-guide/dist/colors';

export const wrapperStyle = css(
  spacing.default.paddingTopMedium,
  spacing.default.paddingBottomXlarge,
  {
    width: '100%',
    color: slateGrey2,
    maxWidth: '900px',
  },
);
