import { css } from '@headspace/hs-web-style-guide';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as flex from '@headspace/hs-web-style-guide/dist/flex';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';
import {
  carrarra,
  doveGrey,
  silverChalice,
} from '@headspace/hs-web-style-guide/dist/colors';

export const dividerStyle = css(
  spacing.default.marginTopSmall,
  spacing.default.marginBottomSmall,
  {
    color: carrarra,
  },
);

export const rowContainer = css(
  spacing.default.paddingLeftSmall,
  spacing.default.paddingRightSmall,
  {
    ' label': css(font.p2, {
      fontWeight: 'bold',
      lineHeight: 'initial',
    }),

    ' .radioDescription': css(
      font.uiRegularS,
      spacing.default.marginTopXsmall,
      {
        color: doveGrey,
        whiteSpace: 'pre-line',

        [`@media (min-width: ${breakpoints.default.l}px)`]: css(
          font.uiRegularL,
        ),
      },
    ),

    ' p': css(
      font.uiRegularS,
      spacing.default.marginTopNone,
      spacing.default.marginBottomNone,
      {
        color: silverChalice,

        [`@media (min-width: ${breakpoints.default.l}px)`]: css(
          font.uiRegularL,
        ),
      },
    ),

    ' div': {
      ' div:first-child + div': css(flex.default.alignItemsCenter),
    },
  },
);
