import { css } from '@headspace/hs-web-style-guide';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';
import {
  comet,
  white,
  pampas,
} from '@headspace/hs-web-style-guide/dist/colors';
const { flex, justifyContentCenter } = flexSet.default;

export const wrapperStyle = css(flex, justifyContentCenter, {
  width: '100%',
});

export const bannerStyle = css({
  backgroundColor: comet,
});

export const bannerContentStyle = css(
  font.p4,
  spacing.default.paddingTopXsmall,
  spacing.default.paddingBottomXsmall,
  spacing.default.paddingLeftSmall,
  spacing.default.paddingRightSmall,
  {
    color: white,
    [`@media (min-width: ${breakpoints.default.l}px)`]: css(
      font.p3,
      flex,
      justifyContentCenter,
    ),
  },
);

export const linkStyle = css({
  color: white,
});

export const containerStyle = css({
  borderTop: `2px solid ${pampas}`,
});

export const headerStyle = css({
  width: '100%',
  height: '70px',
  color: comet,
  textTransform: 'uppercase',
  background: '#FAFAFA',
  [`@media (max-width: ${breakpoints.default.l}px)`]: {
    display: 'none',
  },
});

export const backBtnStyle = css({
  position: 'absolute',
  left: 0,
  marginLeft: '25px',
});
