import { css } from '@headspace/hs-web-style-guide';
import * as buttons from '@headspace/hs-web-style-guide/dist/buttons';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import { comet, merino } from '@headspace/hs-web-style-guide/dist/colors';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as downSVG from './images/down.svg';
const flex = require('@headspace/hs-web-style-guide/dist/flex').default;

export const wrapper = css(spacing.default.paddingTopMedium, {
  width: '100%',
  maxWidth: '900px',
});

export const supportButtonWrapper = css(
  flex.flex,
  spacing.default.paddingTopXsmall,
  flex.justifyContentCenter,
  { width: '100%' },
);

export const supportButtonStyle = css(
  buttons['createSquareSolidButton'](merino, comet),
  { width: '100%' },
);

export const fieldStyling = css(spacing.default.paddingTopXsmall, {
  ' label': font.uiRegularS,
});

export const selectFieldWrapper = css(spacing.default.paddingBottomXsmall, {
  ' select': {
    width: '100%',
    paddingLeft: 0,
    appearance: 'none',
    margin: 0,
    background: `url(${downSVG}) no-repeat center right`,
    backgroundSize: '13px',
  },
});

export const fieldContainer = css(
  spacing.default.paddingLeftMedium,
  spacing.default.paddingRightMedium,
  {
    ' label': font.uiRegularS,
  },
);
