import { combineReducers, Reducer } from 'redux';
import { reducer as consents, ConsentsState } from './consents/consents';
import { reducer as user, UserState } from './user/user';
import {
  reducer as userData,
  UserState as UserDataState,
} from './user/userData';

export interface RootState {
  user: UserState;
  userData: UserDataState;
  consents: ConsentsState;
}

export const rootReducer: Reducer<RootState> = combineReducers({
  consents,
  user,
  userData,
});
