import { EventType } from '@headspace/web-analytics';
import { createScreenViewContract } from './contracts/screenview';
import { createWebContract } from './contracts/web';

export const tabSelectedEvent = (tab: string) => ({
  contracts: {
    screenview: createScreenViewContract({
      screen_name: tab,
    }),
    web: createWebContract(),
  },
  eventName: 'button clickthrough',
  eventType: EventType.Other,
});
