import { Reducer } from 'redux';
import { defaultLanguage, language } from '../../../i18n';

export enum constants {
  GET_USER = 'user/GET_USER',
  GET_USER_SUCCESS = 'user/GET_USER_SUCCESS',
  GET_USER_COUNTRY_CODE = 'user/GET_USER_COUNTRY_CODE',
  GET_USER_COUNTRY_CODE_SUCCESS = 'user/GET_USER_COUNTRY_CODE_SUCCESS',

  GET_USER_LANGUAGE = 'user/GET_USER_LANGUAGE',
  GET_USER_LANGUAGE_SUCCESS = 'user/GET_USER_LANGUAGE_SUCCESS',

  POST_USER_COUNTRY_CODE = 'user/POST_USER_COUNTRY_CODE',
  POST_USER_COUNTRY_CODE_SUCCESS = 'user/POST_USER_COUNTRY_CODE_SUCCESS',
  GET_USER_PACK_COMPLETIONS = 'user/GET_USER_PACK_COMPLETIONS',
  GET_USER_PACK_COMPLETIONS_SUCCESS = 'user/GET_USER_PACK_COMPLETIONS_SUCCESS',
  GET_USER_APPBOY_DATA = 'user/GET_USER_APPBOY_DATA',
  GET_USER_APPBOY_DATA_SUCCESS = 'user/GET_USER_APPBOY_DATA_SUCCESS',
}

export interface User {
  userId?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  displayName?: string;
  countryCode?: string;
  language?: language;
  timezone?: string;
}
export interface UserAppboyData {
  pushSubscribe?: string;
  emailSubscribe?: string;
  timeZone?: string;
}

export interface GetUserAction {
  type: constants.GET_USER;
}

export interface GetUserSuccessAction {
  user: User;
  type: constants.GET_USER_SUCCESS;
}

export interface GetUserCountryCodeAction {
  type: constants.GET_USER_COUNTRY_CODE;
}

export interface GetUserCountryCodeSuccessAction {
  userCountryCode: string;
  type: constants.GET_USER_COUNTRY_CODE_SUCCESS;
}

export interface GetUserLanguageAction {
  type: constants.GET_USER_LANGUAGE;
}

export interface GetUserLanguageSuccessAction {
  userLanguage: string;
  type: constants.GET_USER_LANGUAGE_SUCCESS;
}

export interface PostUserCountryCodeAction {
  userCountryCode: string;
  type: constants.POST_USER_COUNTRY_CODE;
}

export interface PostUserCountryCodeSuccessAction {
  userCountryCode: string;
  type: constants.POST_USER_COUNTRY_CODE_SUCCESS;
}

export interface GetUserPackCompletionsAction {
  type: constants.GET_USER_PACK_COMPLETIONS;
}

export interface GetUserPackCompletionsSuccessAction {
  userPackCompletions: string[];
  type: constants.GET_USER_PACK_COMPLETIONS_SUCCESS;
}

export interface GetUserAppboyDataAction {
  type: constants.GET_USER_APPBOY_DATA;
}

export interface GetUserAppboyDataSuccessAction {
  userAppboyData: UserAppboyData;
  type: constants.GET_USER_APPBOY_DATA_SUCCESS;
}

export namespace UserDataActions {
  export const getUser = (): GetUserAction => ({
    type: constants.GET_USER,
  });

  export const getUserSuccess = (user: User): GetUserSuccessAction => {
    return {
      user,
      type: constants.GET_USER_SUCCESS,
    };
  };

  export const getUserCountryCode = (): GetUserCountryCodeAction => ({
    type: constants.GET_USER_COUNTRY_CODE,
  });

  export const getUserCountryCodeSuccess = (
    userCountryCode: string,
  ): GetUserCountryCodeSuccessAction => {
    return {
      userCountryCode,
      type: constants.GET_USER_COUNTRY_CODE_SUCCESS,
    };
  };

  export const getUserLanguage = (): GetUserLanguageAction => ({
    type: constants.GET_USER_LANGUAGE,
  });

  export const getUserLanguageSuccess = (
    userLanguage: string,
  ): GetUserLanguageSuccessAction => {
    return {
      userLanguage: userLanguage || defaultLanguage,
      type: constants.GET_USER_LANGUAGE_SUCCESS,
    };
  };

  export const postUserCountryCode = (
    userCountryCode: string,
  ): PostUserCountryCodeAction => ({
    userCountryCode,
    type: constants.POST_USER_COUNTRY_CODE,
  });

  export const postUserCountryCodeSuccess = (
    userCountryCode: string,
  ): PostUserCountryCodeSuccessAction => ({
    userCountryCode,
    type: constants.POST_USER_COUNTRY_CODE_SUCCESS,
  });

  export const getUserPackCompletions = (): GetUserPackCompletionsAction => ({
    type: constants.GET_USER_PACK_COMPLETIONS,
  });

  export const getUserPackCompletionsSuccess = (
    userPackCompletions: string[],
  ): GetUserPackCompletionsSuccessAction => {
    return {
      userPackCompletions,
      type: constants.GET_USER_PACK_COMPLETIONS_SUCCESS,
    };
  };

  export const getUserAppboyData = (): GetUserAppboyDataAction => ({
    type: constants.GET_USER_APPBOY_DATA,
  });

  export const getUserAppboyDataSuccess = (
    userAppboyData: UserAppboyData,
  ): GetUserAppboyDataSuccessAction => {
    return {
      userAppboyData,
      type: constants.GET_USER_APPBOY_DATA_SUCCESS,
    };
  };
}

export const defaultState = {
  user: {},
  userCountryCode: '',
  userLanguage: 'en',
  userPackCompletions: [],
  userAppboyData: {},
};

export interface UserState {
  user: User;
  userCountryCode: string;
  userLanguage: string;
  userPackCompletions: string[];
  userAppboyData: UserAppboyData;
}

export const reducer: Reducer<UserState> = (state = defaultState, action) => {
  switch (action.type) {
    case constants.GET_USER_SUCCESS:
      return { ...state, user: action.user };
    case constants.GET_USER_COUNTRY_CODE_SUCCESS:
    case constants.POST_USER_COUNTRY_CODE:
    case constants.POST_USER_COUNTRY_CODE_SUCCESS:
      return { ...state, userCountryCode: action.userCountryCode };
    case constants.GET_USER_LANGUAGE_SUCCESS:
      return {
        ...state,
        userLanguage: action.userLanguage,
      };
    case constants.GET_USER_PACK_COMPLETIONS_SUCCESS:
      return { ...state, userPackCompletions: action.userPackCompletions };
    case constants.GET_USER_APPBOY_DATA_SUCCESS:
      return { ...state, userAppboyData: action.userAppboyData };
    case constants.GET_USER:
    case constants.GET_USER_COUNTRY_CODE:
    case constants.GET_USER_PACK_COMPLETIONS:
    case constants.GET_USER_APPBOY_DATA:
    default:
      return state;
  }
};
