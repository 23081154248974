import 'babel-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { i18n } from './i18n';
import App from './components/App';
import { store } from './redux/store';
import env from './config/env';
import { StatsigProvider } from 'statsig-react';
import { createStatsigConfig } from './util/statsig';
import { injectMparticleScript } from './util/mparticle';

Sentry.init({
  dsn: env.sentryDSN,
});

const statsigConfig = createStatsigConfig();

ReactDOM.render(
  <StatsigProvider {...statsigConfig}>
    <CookiesProvider>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </CookiesProvider>
  </StatsigProvider>,
  document.getElementById('root'),
);

injectMparticleScript(document);
