import { css } from '@headspace/hs-web-style-guide';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';

export const labelStyle = css(font.utility2, font.uppercase, {
  marginRight: 5,
  marginLeft: 8,
  [`@media (min-width: ${breakpoints.default.l}px)`]: {
    marginRight: 12,
    marginLeft: 16,
  },
});
