import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RootState } from '../../redux/modules';
import {
  ConsentsActions,
  Consent,
  EnactedConsent,
} from '../../redux/modules/consents/consents';
import { RadioLabels } from '../RadioLabels';
import { ConsentsSection } from '../ConsentsSection';
import { wrapperStyle } from './styles';
import { trackSE } from '../../util/snowplow';
import i18next from 'i18next';
import { language } from '../../i18n';

export interface UserPermissionsOwnProps {
  t: i18next.TFunction;
  language: language;
}

export interface UserPermissionsStateProps {
  allUserConsents: Consent[];
  enactedUserConsents: EnactedConsent[];
}

export interface UserPermissionsDispatchProps {
  getAllUserConsents: () => void;
  getEnactedUserConsents: () => void;
  putUserConsent: (status: string, consentId: number) => void;
}

export type UserPermissionsProps = UserPermissionsOwnProps &
  UserPermissionsStateProps &
  UserPermissionsDispatchProps;

export class UserPermissionsComponent extends React.Component<
  UserPermissionsProps
> {
  constructor(props: UserPermissionsProps) {
    super(props);
    this.props.getEnactedUserConsents();
    this.props.getAllUserConsents();
    trackSE('data_settings_nav', 'screen', 'permissions');
  }

  render() {
    const {
      t,
      enactedUserConsents,
      allUserConsents,
      putUserConsent,
      language,
    } = this.props;
    const options = [t('yes'), t('no')];

    return (
      <div {...wrapperStyle}>
        <RadioLabels options={options} />
        <ConsentsSection
          t={t}
          language={language}
          options={options}
          enactedUserConsents={enactedUserConsents}
          allUserConsents={allUserConsents}
          putUserConsent={putUserConsent}
        />
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  UserPermissionsStateProps,
  UserPermissionsOwnProps,
  RootState
> = ({
  consents: { allUserConsents, enactedUserConsents },
  userData: { userLanguage },
}) => ({
  allUserConsents,
  enactedUserConsents,
  language: userLanguage,
});

export const UserPermissions = connect<
  UserPermissionsStateProps,
  UserPermissionsDispatchProps,
  UserPermissionsOwnProps,
  RootState
>(
  mapStateToProps,
  ConsentsActions,
)(UserPermissionsComponent) as any;
