import * as React from 'react';
import { RootState } from '../../redux/modules';
import {
  DisplayField,
  FormFieldLabel,
  SelectField,
  SelectOption,
} from '@headspace/hs-web-component-library';
import { connect, MapStateToProps } from 'react-redux';
import { getEmailHelpUrl } from '../../i18n/utils/helpdeskUrlLocalizer';
import {
  UserDataActions,
  User,
  UserAppboyData,
} from '../../redux/modules/user/userData';
import {
  wrapper,
  supportButtonStyle,
  supportButtonWrapper,
  fieldStyling,
  selectFieldWrapper,
  fieldContainer,
} from './styles';
import { countries } from '../../util/countries';
import { trackSE } from '../../util/snowplow';
import i18next from 'i18next';

export interface UserDataOwnProps {
  t: i18next.TFunction;
}

export interface UserDataStateProps {
  user: User;
  userCountryCode: string;
  userLanguage: string;
  userPackCompletions: string[];
  userAppboyData: UserAppboyData;
}

export interface UserDataDispatchProps {
  getUser: () => void;
  getUserCountryCode: () => void;
  getUserLanguage: () => void;
  getUserPackCompletions: () => void;
  getUserAppboyData: () => void;
  postUserCountryCode: (userCountryCode: string) => void;
}

export type UserDataProps = UserDataOwnProps &
  UserDataStateProps &
  UserDataDispatchProps;

export class UserDataComponent extends React.Component<UserDataProps> {
  constructor(props: UserDataProps) {
    super(props);
    this.props.getUser();
    this.props.getUserCountryCode();
    this.props.getUserLanguage();
    this.props.getUserPackCompletions();
    this.props.getUserAppboyData();
    trackSE('data_settings_nav', 'screen', 'info');
  }

  emailSupport(link: string) {
    trackSE('input', 'click', 'email_support', 'data_settings_nav');
    location.href = link;
  }

  generateCountries(): JSX.Element[] {
    return [
      // @ts-expect-error
      <SelectOption
        key="N/A"
        value="Not Selected"
        disabled={true}
        hidden={true}
      >
        Not Selected
      </SelectOption>,
      ...countries.map(country => {
        return (
          // @ts-expect-error
          <SelectOption key={country.code} value={country.code}>
            {country.name}
          </SelectOption>
        );
      }),
    ];
  }

  displayField(label: string, value?: string | string[]): JSX.Element | void {
    if (value && value.length > 0) {
      return (
        <div {...fieldStyling}>
          <DisplayField label={label} value={value} />
        </div>
      );
    }
  }

  capitalize(input?: string): string {
    if (input) {
      return `${input[0].toUpperCase()}${input.slice(1)}`;
    }
    return '';
  }

  render() {
    const {
      t,
      user,
      userCountryCode,
      userLanguage,
      userPackCompletions,
      postUserCountryCode,
      userAppboyData,
    } = this.props;
    const selectUpdateAction = (event: Event) => {
      const country = event.target as HTMLSelectElement;
      postUserCountryCode(country.value);
      trackSE('input', 'click', 'edit_info', 'data_settings_nav');
    };
    const language = userLanguage ? userLanguage.slice(0, 2) : '';

    return (
      <div {...wrapper}>
        <div {...fieldContainer}>
          <div {...selectFieldWrapper}>
            <FormFieldLabel label={t('user.country')} />
            <SelectField
              width={250}
              onChange={selectUpdateAction}
              value={userCountryCode ? userCountryCode : 'Not Selected'}
            >
              {this.generateCountries()}
            </SelectField>
          </div>
          {this.displayField(t('user.userId'), user.userId)}
          {this.displayField(t('user.firstname'), user.firstname)}
          {this.displayField(t('user.lastname'), user.lastname)}
          {this.displayField(t('user.email'), user.email)}
          {this.displayField(
            t('user.language'),
            this.capitalize(userLanguage).slice(0, 2),
          )}
          {this.displayField(t('user.timezone'), userAppboyData.timeZone)}
          {this.displayField(t('user.packHistory'), userPackCompletions)}
          {this.displayField(
            t('user.pushSubscribe'),
            this.capitalize(userAppboyData.pushSubscribe),
          )}
          {this.displayField(
            t('user.emailSubscribe'),
            this.capitalize(userAppboyData.emailSubscribe),
          )}
        </div>

        <div {...supportButtonWrapper}>
          <button
            {...supportButtonStyle}
            onClick={() => this.emailSupport(getEmailHelpUrl(language))}
          >
            {t('user.emailSupport')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  UserDataStateProps,
  UserDataOwnProps,
  RootState
> = ({
  userData: {
    user,
    userCountryCode,
    userLanguage,
    userPackCompletions,
    userAppboyData,
  },
}) => ({
  user,
  userCountryCode,
  userLanguage,
  userPackCompletions,
  userAppboyData,
});

export const UserData = connect<
  UserDataStateProps,
  UserDataDispatchProps,
  UserDataOwnProps,
  RootState
>(
  mapStateToProps,
  UserDataActions,
)(UserDataComponent) as any;
