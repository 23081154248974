import { Icon, TabInfo } from '@headspace/hs-web-component-library';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import i18next from 'i18next';
import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect, MapStateToProps } from 'react-redux';
import { Redirect } from 'react-router';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import env from '../../config/env';
import { getPrivacyPolicyHelpUrl } from '../../i18n/utils/helpdeskUrlLocalizer';
import { RootState } from '../../redux/modules';
import { isUserOnHeadspaceiOSApp } from '../../util/devices';
import { PartnerDataSharing } from '../PartnerDataSharing/PartnerDataSharing';
import { TabBar } from '../TabBar/TabBar';
import { UserData } from '../UserData/UserData';
import { UserPermissions } from '../UserPermissions/UserPermissions';
import * as backSVG from './images/back.svg';
import {
  backBtnStyle,
  bannerContentStyle,
  bannerStyle,
  containerStyle,
  headerStyle,
  linkStyle,
  wrapperStyle,
} from './styles';
import { useGetLogEvent } from '@headspace/web-analytics';
import { tabSelectedEvent } from '../../analytics';

enum TabURL {
  INFO = 'info',
  PERMISSIONS = 'permissions',
  PARTNER_DATA_SHARING = 'partnerDataSharing',
}

const { flex, justifyContentCenter, alignItemsCenter } = flexSet.default;

export interface DataPageOwnProps {
  t: i18next.TFunction;
}

export interface DataPageStateProps {
  userLanguage: string;
  hasIndividualConsents: boolean;
}

export type DataPageProps = DataPageOwnProps &
  DataPageStateProps &
  RouteComponentProps &
  WithNamespaces;

export const DataPageComponent: React.FC<DataPageProps> = props => {
  const { t, userLanguage, match, history } = props;
  const logEvent = useGetLogEvent();
  const [_, setActiveSubpage] = React.useState(TabURL.INFO);
  const path = match.path;

  const shouldRenderPartnerDataSharingTab = props.hasIndividualConsents;

  const dataPageTabs = [];
  dataPageTabs.push({ label: `${t('dataPage.info.label')}`, url: TabURL.INFO });

  if (!isUserOnHeadspaceiOSApp()) {
    dataPageTabs.push({
      label: `${t('dataPage.permissions.label')}`,
      url: TabURL.PERMISSIONS,
    });
  }

  if (shouldRenderPartnerDataSharingTab) {
    dataPageTabs.push({
      label: `${t('dataPage.partnerDataSharing.label')}`,
      url: TabURL.PARTNER_DATA_SHARING,
    });
  }

  const tabUpdateAction = React.useCallback(
    (tab: TabInfo) => {
      // setActivePage doesn't actually control the rendering now, instead only used
      // to update the active tab in the tab bar. The rendering is controlled by the
      // Route components.

      logEvent(tabSelectedEvent(tab.url as TabURL));
      setActiveSubpage(tab.url as TabURL);
      history.push(`${path}/${tab.url}`);
    },
    [history, path, setActiveSubpage, dataPageTabs],
  );

  const defaultActiveTab = dataPageTabs.findIndex(
    tab =>
      `${path}/${tab.url}`.toLowerCase() ===
      props.location.pathname.toLowerCase(),
  );

  return (
    <div>
      <Header t={t} />
      <div {...containerStyle}>
        <TabBar
          tabs={dataPageTabs}
          tabUpdateAction={tabUpdateAction}
          defaultActiveTab={defaultActiveTab}
        />
        <Switch>
          <Route path={`/data/${TabURL.INFO}`}>
            <UserDataTabPage t={props.t} language={userLanguage} />
          </Route>
          <Route path={`/data/${TabURL.PERMISSIONS}`}>
            <UserPermissionsTabPage t={props.t} />
          </Route>
          <Route path={`/data/${TabURL.PARTNER_DATA_SHARING}`}>
            <PartnerDataSharingTabPage t={props.t} />
          </Route>
          <Route exact path={path}>
            <Redirect to={`/data/${TabURL.INFO}`} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps: MapStateToProps<
  DataPageStateProps,
  DataPageOwnProps,
  RootState
> = ({ userData: { userLanguage }, consents: { individualConsents } }) => ({
  userLanguage,
  hasIndividualConsents: individualConsents.length > 0,
});

DataPageComponent.displayName = 'DataPage';
export const DataPage = withNamespaces('translation')(
  withRouter(
    connect<DataPageStateProps>(mapStateToProps, {})(DataPageComponent),
  ),
);

function Header(props: { t: (key: string) => string }) {
  return (
    <div
      {...flex}
      {...justifyContentCenter}
      {...alignItemsCenter}
      {...font.utility2}
      {...headerStyle}
    >
      <a href={`${env.webApp}profile/account`} {...backBtnStyle}>
        <Icon src={backSVG} height={20} width={20} />
      </a>
      <span>{props.t('myData')}</span>
    </div>
  );
}

interface UserDataTabPageProps {
  t: (key: string) => string;
  language: string;
}
const UserDataTabPage: React.FC<UserDataTabPageProps> = ({ t, language }) => {
  return (
    <div>
      <div {...bannerStyle}>
        <div {...bannerContentStyle}>
          <p className="preLink">
            {t('dataPage.info.banner.prelink')}{' '}
            <a {...linkStyle} href={getPrivacyPolicyHelpUrl(language)}>
              {t('dataPage.info.banner.link')}
            </a>
          </p>
        </div>
      </div>
      <div {...wrapperStyle}>
        <UserData t={t} />
      </div>
    </div>
  );
};

interface UserPermissionsTabPageProps {
  t: (key: string) => string;
}

const UserPermissionsTabPage: React.FC<UserPermissionsTabPageProps> = ({
  t,
}) => {
  return (
    <div>
      <div {...bannerStyle}>
        <div {...bannerContentStyle}>
          <p>
            {t('dataPage.permissions.banner.prelink')}
            <br />
            {t('dataPage.permissions.banner.prelink2')}{' '}
            <a {...linkStyle} href={t('links.privacyPolicy')}>
              {t('dataPage.permissions.banner.link')}
            </a>{' '}
            {t('dataPage.permissions.banner.postlink')}
          </p>
        </div>
      </div>
      <div {...wrapperStyle}>
        <UserPermissions t={t} />
      </div>
    </div>
  );
};

interface PartnerDataSharingTabPageProps {
  t: (key: string) => string;
}

const PartnerDataSharingTabPage: React.FC<PartnerDataSharingTabPageProps> = ({
  t,
}) => {
  return (
    <div>
      <div {...wrapperStyle}>
        <PartnerDataSharing t={t} />
      </div>
    </div>
  );
};
