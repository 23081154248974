import { Reducer } from 'redux';

export enum constants {
  SET_JWT = 'user/SET_JWT',
  SET_USERID = 'user/SET_USERID',
}

export interface SetJwtAction {
  type: constants.SET_JWT;
  jwt: string;
}

export interface SetUserIdAction {
  type: constants.SET_USERID;
  userId: string;
}

export namespace UserActions {
  export const setJwt = (jwt: string): SetJwtAction => ({
    jwt,
    type: constants.SET_JWT,
  });

  export const setUserId = (userId: string): SetUserIdAction => ({
    userId,
    type: constants.SET_USERID,
  });
}

export const defaultState = {
  jwt: '',
  userId: '',
};

export interface UserState {
  jwt: string;
  userId: string;
}

export const reducer: Reducer<UserState> = (state = defaultState, action) => {
  switch (action.type) {
    case constants.SET_JWT:
      return { ...state, jwt: action.jwt };
    case constants.SET_USERID:
      return { ...state, userId: action.userId };
    default:
      return state;
  }
};
