import { css } from '@headspace/hs-web-style-guide';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';
import {
  carrarra,
  comet,
  fountainBlue,
} from '@headspace/hs-web-style-guide/dist/colors';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';

const {
  marginRightXsmall,
  marginLeftXsmall,
  paddingBottomXxxsmall,
} = spacing.default;

const { flex, flex1, justifyContentSpaceBetween } = flexSet.default;

export const radioWrapperStyles = css(flex, justifyContentSpaceBetween);

export const labelStyles = (color: string) =>
  css(font.p3, flex1, justifyContentSpaceBetween, {
    color,
    [breakpoints.maxWidths.l]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  });

export const valueStyles = css(font.p3, paddingBottomXxxsmall, {
  color: comet,
});

export const selectOptionStyle = css({
  color: comet,
  fontSize: 16,
  textDecoration: 'none',
  zIndex: 1,
});

export const selectFieldStyle = (minWidth: number) => {
  return css({
    minWidth,
    outline: 'none',
  });
};

export const selectFieldTextStyle = css(font.p3, {
  backgroundColor: 'transparent',
  color: comet,
  border: 'none',
});

export const radioButtonStyle = css(marginRightXsmall, marginLeftXsmall, {
  height: 25,
  width: 25,
  appearance: 'none',
  borderRadius: 25,
  outline: 'none',
  border: `2px solid ${carrarra}`,
  ':checked': {
    border: `8px solid ${fountainBlue}`,
  },
});
