import * as React from 'react';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import { labelStyle } from './styles';
const { flex, justifyContentFlexEnd } = flexSet.default;
const { paddingLeftSmall, paddingRightSmall } = spacing.default;

interface ContainerProps {
  options: string[];
}
export const RadioLabels: React.SFC<ContainerProps> = ({
  options,
  ...props
}) => (
  <div
    {...flex}
    {...justifyContentFlexEnd}
    {...paddingLeftSmall}
    {...paddingRightSmall}
    {...props}
  >
    {options.map(option => (
      <Label key={`${option}-label`}>{option}</Label>
    ))}
  </div>
);

interface LabelProps {}
export const Label: React.SFC<LabelProps> = props => (
  <label {...labelStyle} {...props} />
);
