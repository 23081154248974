let apiClient: any;
if (process.env.NODE_ENV === 'test') {
  apiClient = require('./__mocks__/api-stub-client').default;
} else {
  apiClient = require('../../lib/api-client').default;
}

import {
  constants,
  ConsentsActions,
  IndividualConsent,
  EnactedIndividualConsent,
  PutUserConsentAction,
} from '../modules/consents/consents';

export namespace ConsentsMiddleware {
  export const middleware = (store: any) => (next: any) => async (
    action: any,
  ) => {
    next(action);
    const { dispatch } = store;
    // set up api client
    const {
      user: { jwt, userId },
    } = store.getState();
    const api = new apiClient(jwt);

    switch (action.type) {
      case constants.CHECK_CONSENTS_STATUS:
        dispatch(ConsentsActions.getV1UserConsents());
        return api
          .get('user/user-consents/check-status', { userId }, false)
          .then((resp: any) => {
            if (resp.needsConsentUpdate) {
              dispatch(ConsentsActions.getUserConsents());
            }
            dispatch(
              ConsentsActions.checkConsentsStatusSuccess(
                resp.needsConsentUpdate,
              ),
            );
          });

      case constants.GET_USER_CONSENTS:
        return api
          .get('user/consents/user', { userId }, false)
          .then((resp: any) => {
            const consents = resp.data;
            dispatch(ConsentsActions.getUserConsentsSuccess(consents));
          });

      case constants.GET_V1_USER_CONSENTS:
        return api
          .get('user/v1/user-consents', { userId, include: 'consent' }, false)
          .then((resp: any) => {
            handleGetV1UserConsentsResponse(resp, dispatch);
          });

      case constants.GET_ALL_USER_CONSENTS:
        return api
          .get('user/consents/user', { userId, showAll: true })
          .then((resp: any) => {
            const consents = resp.consents || {};
            dispatch(
              ConsentsActions.getAllUserConsentsSuccess(
                Object.values(consents),
              ),
            );
          });

      case constants.GET_ENACTED_USER_CONSENTS:
        return api.get('user/user-consents', { userId }).then((resp: any) => {
          const userConsents = resp.userConsents || {};
          dispatch(
            ConsentsActions.getEnactedUserConsentsSuccess(
              Object.values(userConsents),
            ),
          );
        });

      case constants.PUT_USER_CONSENT:
        const {
          status,
          consentId,
          successDispatch,
        } = action as PutUserConsentAction;
        const today = new Date();
        const clientUpdatedAt = today.toISOString();
        const updatedConsentId = Number(consentId);
        const body = {
          userId,
          status,
          consentId: updatedConsentId,
          clientUpdatedAt,
        };
        return api.put('user/user-consents', body).then(() => {
          dispatch(ConsentsActions.putUserConsentSuccess());
          if (successDispatch) {
            dispatch(successDispatch);
          }
        });
    }
  };
}

const handleGetV1UserConsentsResponse = (resp: any, dispatch: any) => {
  const consents = resp.consents.filter(
    (consent: IndividualConsent) => consent.scope === 'INDIVIDUAL',
  );
  const consentIds = consents.map((consent: IndividualConsent) => consent.id);
  const userConsents = resp.userConsents.filter(
    (userConsent: EnactedIndividualConsent) =>
      consentIds.includes(userConsent.consentId),
  );
  dispatch(ConsentsActions.getV1UserConsentsSuccess(userConsents, consents));
};
