import env from '../config/env';
import 'whatwg-fetch';

const jsonApiNormalizer = require('json-api-normalizer').default;

export default class apiClient {
  token?: string;

  constructor(token?: string) {
    this.token = token;
  }

  getQueryString(params: any) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => `${esc(k)}=${esc(params[k])}`)
      .join('&');
  }

  request(endpoint: string, method: string, body?: any, jsonApi?: boolean) {
    const options = {
      method,
      headers: {
        Authorization: `bearer ${this.token}`,
        'Content-Type': 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    };

    return fetch(`${env.apiHost}${endpoint}`, options).then(resp => {
      const json = resp.json();
      return json.then(data => {
        return jsonApi ? Promise.resolve(jsonApiNormalizer(data)) : data;
      });
    });
  }

  get(endpoint: string, queryParams = {} as any, jsonApi = true as boolean) {
    const query = this.getQueryString(queryParams);
    return this.request(`${endpoint}?${query}`, 'GET', null, jsonApi);
  }

  post(endpoint: string, body?: any) {
    return this.request(endpoint, 'POST', body);
  }

  put(endpoint: string, body?: any) {
    return this.request(endpoint, 'PUT', body);
  }
}
