import * as React from 'react';
import { withRouter } from 'react-router-dom';
import * as jwtDecode from 'jwt-decode';
import { withCookies, Cookies } from 'react-cookie';
import { connect, MapStateToProps } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { Main } from './Main';
import apiClient from '../lib/api-client';
import { UserActions } from '../redux/modules/user/user';
import { UserDataActions } from '../redux/modules/user/userData';

import { ConsentsActions } from '../redux/modules/consents/consents';
import { getCookieFromUrlFragment } from '../util/cookies';
import '../fonts/index.css';
import env from '../config/env';
import i18next from 'i18next';
import { Environment, WebAnalyticsProvider } from '@headspace/web-analytics';
import { setMParticleUser } from '../util/mparticle';

export interface AppOwnProps {
  cookies: Cookies;
  t: i18next.TFunction;
}

export interface AppStateProps {
  jwt: string;
  needsConsentUpdate: boolean;
}

export interface AppDispatchProps {
  setUserId: (userId: string) => void;
  getUserData: () => void;
  getUserLanguage: () => void;
  setJwt: (jwt: string) => void;
  checkConsentsStatus: () => void;
}

export type AppProps = AppOwnProps & AppStateProps & AppDispatchProps;
interface AppState {
  userId?: string;
}

const getAnalyticsEnvironment = (env: string) => {
  if (env === 'production') return Environment.Production;
  if (env === 'staging') return Environment.Staging;
  if (env === 'development') return Environment.Staging;
  return Environment.Integration;
};

export class AppComponent extends React.Component<AppProps, AppState> {
  api?: apiClient;
  userMeta: any;
  constructor(props: AppProps) {
    super(props);
    const {
      checkConsentsStatus,
      cookies,
      getUserData,
      getUserLanguage,
      setJwt,
      setUserId,
    } = this.props;

    const mobileJWT = this._getCookie('hsjwt', cookies);
    const webJWT = this._getCookie(env.authCookie || 'hsjwt', cookies);
    const hsjwt = mobileJWT || webJWT;

    if (hsjwt) {
      setJwt(hsjwt); // redux store
      try {
        this.api = new apiClient(hsjwt);
      } catch (e) {
        console.error(e);
      }
    } else {
      this._logMobileException('Rendering Mobile Application without user JWT');
      const webviewsURI = encodeURI(env.self || '/');
      window.location.replace(`${env.webApp}login?redirect_uri=${webviewsURI}`);
      return;
    }

    let userId;
    try {
      this.userMeta = jwtDecode(hsjwt);
      userId =
        this.userMeta.userId || this.userMeta[`${env.auth0Audience}/hsId`];
    } catch (e) {
      this.userMeta = {};
    }

    if (userId) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId });
      });

      setUserId(userId); // redux store
      getUserData();
      getUserLanguage();
      checkConsentsStatus();
      this.state = {
        userId,
      };
      setMParticleUser(userId);
    }
  }

  _logMobileException(message: string): void {
    const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    const isAndroid = !!window.Android;

    if (isMobileBrowser || isAndroid) {
      const err = new Error(message);
      Sentry.captureException(err);
    }
  }

  _getCookie(cookieName: string, cookies: any): string {
    return cookies.get(cookieName) || getCookieFromUrlFragment(cookieName);
  }

  render() {
    if (!this.props.jwt) {
      // TODO: add error boundary here
      return null;
    }
    return (
      <div className="app">
        <WebAnalyticsProvider
          initializeExperiment={false}
          userId={this.state.userId}
          environment={getAnalyticsEnvironment(env.nodeEnv)}
        >
          <Main t={this.props.t} />
        </WebAnalyticsProvider>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<AppStateProps, AppOwnProps, any> = ({
  user: { jwt },
  consents: { needsConsentUpdate },
}) =>
  ({
    jwt,
    needsConsentUpdate,
  } as any);
const mapActionsToProps = {
  setUserId: UserActions.setUserId,
  getUserData: UserDataActions.getUser,
  getUserLanguage: UserDataActions.getUserLanguage,
  setJwt: UserActions.setJwt,
  checkConsentsStatus: ConsentsActions.checkConsentsStatus,
};
export const App = withRouter(
  connect<AppStateProps, AppDispatchProps, AppOwnProps, any>(
    mapStateToProps,
    mapActionsToProps,
  )(AppComponent) as any,
) as any;

export default withCookies(App);
