export const isUserOnHeadspaceiOSApp = () => {
  // @ts-ignore
  const standalone = navigator.standalone;
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);
  const ios_ipad_webview = ios && !safari;

  if (ios) {
    if ((!standalone && !safari) || ios_ipad_webview) {
      return true;
    }
  }
  return false;
};
