import { EventEmitter2 } from 'eventemitter2';

export interface WebviewWindow extends Window {
  Android: any;
  webkit: any;
  WEBVIEW: NativeBridge;
}

export interface nativeAction {
  type: string;
  data?: any;
}

export interface snowplowParams {
  category: string;
  action: string;
  label: string;
  property: string;
}

export class NativeBridge extends EventEmitter2 {
  Android?: any;
  webkit?: any;
  isAndroid: boolean;
  isIos: boolean;

  constructor(webviewWindow: WebviewWindow) {
    super();
    this.Android = webviewWindow.Android;
    this.webkit = webviewWindow.webkit;
    this.isAndroid = !!this.Android;
    this.isIos = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  nativeAction(action = {} as nativeAction) {
    if (typeof action !== 'object') return;
    this.emit(action.type, action.data);
  }

  send(activity: any, data?: any, action?: () => void) {
    if (this.isAndroid) {
      this.androidSend(activity);
    } else if (this.isIos) {
      this.iosSend(activity, data);
    } else if (action) {
      action();
    }
  }

  androidSend(activity: string) {
    return this.android('performAction', activity);
  }

  android(method: string, ...androidPayload: any[]) {
    try {
      return this.Android[method](...androidPayload);
    } catch (e) {
      console.error(e);
    }
  }

  iosSend(activity: string, data?: any) {
    return this.ios('pageAction', { data, type: activity });
  }

  ios(method: string, ...iosPayload: any[]) {
    try {
      return this.webkit.messageHandlers[method].postMessage(...iosPayload);
    } catch (e) {
      console.error(e);
    }
  }

  track(trackingParams: snowplowParams) {
    return this.send('track', trackingParams);
  }

  onPageLoaded() {
    if (this.isAndroid) {
      return this.android('onPageLoaded');
    }
    if (this.isIos) {
      return this.iosSend('pageLoaded');
    }
  }

  onError(e: Error) {
    const message = e.message.toString();
    if (this.isAndroid) {
      this.Android.onError && this.Android.onError(message);
    } else if (this.isIos) {
      this.iosSend('error', { message });
    } else {
      console.log(e);
    }
  }
}

const nativeBridge = new NativeBridge((window as unknown) as WebviewWindow);

export default nativeBridge as NativeBridge;
