import i18n from 'i18next';
import { de } from './translations/de';
import { en } from './translations/en';
import { es } from './translations/es';
import { fr } from './translations/fr';
import { pt } from './translations/pt';

export enum language {
  en = 'en',
  es = 'es',
  de = 'de',
  fr = 'fr',
  pt = 'pt',
}

export const defaultLanguage = language.en;

const options = {
  resources: {
    en,
    es,
    de,
    fr,
    pt,
  },
  // react i18next special options (optional)
  react: {
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default' as any,
    wait: false,
  },
  fallbackLng: defaultLanguage,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  saveMissing: true,
  allowSyntheticDefaults: true,
};

i18n.init(options);

export { i18n };
