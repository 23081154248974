import { WebContract } from '@headspace/hs-events/generated/types/contracts/web';
import env from '../../config/env';

export const createWebContract = (web?: Partial<WebContract>): WebContract => ({
  ...web,
  domain_userid: env.self,
  page_title: document.title || '',
  page_url: window.location.href || '',
  previous_page: document.referrer || '',
  page_referrer: document.referrer || '',
});
