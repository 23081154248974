import env from '../config/env';

export function injectMparticleScript(doc: Document) {
  if (doc.getElementById('mparticle-script')) {
    return;
  }

  const mparticleEnv = env.nodeEnv;
  const script = doc.createElement('script');
  script.id = 'mparticle-script';
  script.src = `https://static.headspace.com/mparticle-scripts/1.0.0/hs-web-analytics_${mparticleEnv}.js`;
  script.async = true;
  doc.head.appendChild(script);
}

export function setMParticleUser(userId: string) {
  const loginIdentityRequest = {
    userIdentities: {
      customerid: userId,
    },
  };

  if (window.mParticle) {
    window.mParticle.Identity.login(loginIdentityRequest);
  }
}
