import { defaultLanguage } from '../';

export const helpdeskLanguageMap = {
  de: 'de',
  en: 'en-us',
  es: 'es-419',
  fr: 'fr',
  pt: 'pt',
};

export const getHomeHelpUrl = (lng: string) => {
  const helpdeskLocale =
    helpdeskLanguageMap[lng] || helpdeskLanguageMap[defaultLanguage];
  return `https://help.headspace.com/hc/${helpdeskLocale}`;
};

export const getPrivacyPolicyHelpUrl = (lng: string) => {
  return `${getHomeHelpUrl(lng)}/categories/360000586073-Privacy-at-Headspace`;
};

export const getEmailHelpUrl = (lng: string) => {
  return `${getHomeHelpUrl(lng)}/requests/new?ticket_form_id=360000044114`;
};
