export const getCookieFromUrlFragment = (cookieName: string): string => {
  try {
    const reg = new RegExp(`${cookieName}=([^&#]*)`, 'i');
    const matchedMetadata = reg.exec(window.location.hash);
    if (!matchedMetadata) {
      return '';
    }
    return matchedMetadata[1];
  } catch (error) {
    return '';
  }
};
