import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './modules';

import { ConsentsMiddleware } from './middleware/consents';
import { UserDataMiddleware } from './middleware/userData';
import rootSaga from './middleware/locale';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const middleware = compose(
  applyMiddleware(ConsentsMiddleware.middleware as any),
  applyMiddleware(UserDataMiddleware.middleware as any),
  applyMiddleware(sagaMiddleware),
);

const store: any = createStore(rootReducer, composeEnhancers(middleware));

sagaMiddleware.run(rootSaga);

export { store };
