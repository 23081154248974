import env from '../../config/env';
import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect, MapStateToProps } from 'react-redux';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import * as buttons from '@headspace/hs-web-style-guide/dist/buttons';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import { trout, white } from '@headspace/hs-web-style-guide/dist/colors';
import { Card } from '@headspace/hs-web-component-library/dist/utilitarian/Card/Card';
import {
  ConsentsActions,
  Consent,
} from '../../redux/modules/consents/consents';
import {
  CardFlow,
  Consumer,
} from '@headspace/hs-web-component-library/dist/utilitarian/CardFlow/CardFlow';
import {
  containerStyles,
  linkStyles,
  cardStyles,
  cardWrapperStyles,
  imgStyles,
  fullWidth,
  buttonStyles,
  imgWrapper,
  titleStyles,
  bodyStyles,
  disableHoverState,
} from './styles';
import nativeBridge from '../../lib/native-bridge';
import { RootState } from '../../redux/modules';
import * as dndSvg from './images/do-not-disturb.svg';
import * as lmSvg from './images/lets-meditate.svg';
import { trackSE } from '../../util/snowplow';
import { language } from '../../i18n';
import i18next from 'i18next';

const {
  marginBottomMedium,
  marginTopMedium,
  marginBottomXsmall,
  paddingLeftMedium,
  paddingRightMedium,
  paddingMedium,
} = spacing.default;

const { flex, column, alignItemsCenter } = flexSet.default;

export interface NextButtonProps {
  children: string;
  ghost: boolean;
  onClick: (args?: any) => void;
}

const NextButtonDefaultProps = {
  ghost: false,
  onClick: () => {},
};

export interface ConsentsReduxState {
  userConsents: any;
  needsConsentLoading: boolean;
  needsConsentUpdate: boolean;
  putConsentLoading: boolean;
}

export const NextButton: React.SFC<NextButtonProps> = props => {
  const { children, ghost, onClick, ...otherProps } = props;
  const buttonType = ghost
    ? 'createLargeGhostButton'
    : 'createLargeSquareSolidButton';
  const noHover = ghost ? disableHoverState : {};
  return (
    <Consumer>
      {({ nextCard }) => (
        <button
          {...(buttons as any)[buttonType](trout, white)}
          {...fullWidth}
          {...buttonStyles}
          {...otherProps}
          {...noHover}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            nextCard();
          }}
        >
          {children}
        </button>
      )}
    </Consumer>
  );
};

export interface ConsentFlowOwnProps {
  t: i18next.TFunction;
  language: language;
}

export interface ConsentFlowStateProps {
  userConsents: Consent[];
  needsConsentUpdate: boolean;
  needsConsentLoading: boolean;
}

export interface ConsentFlowDispatchProps {
  putUserConsent: (status: string, consentId: number) => void;
}

export type ConsentFlowProps = ConsentFlowOwnProps &
  ConsentFlowStateProps &
  ConsentFlowDispatchProps &
  WithNamespaces;

export class ConsentFlowComponent extends React.Component<ConsentFlowProps> {
  componentWillUpdate(nextProps: ConsentFlowProps) {
    const { userConsents } = nextProps;
    let image;
    // preload images
    if (userConsents && userConsents.length) {
      userConsents.forEach(consent => {
        image = new Image();
        image.src = `${env.apiHost}content/media-items/${consent.attributes.mediaItemId}/download-image?width=600`;
      });
    }
  }

  goHome() {
    const webAction = () => {
      window.location.assign(`${env.webApp}`);
    };
    nativeBridge.send('goToHomePage', {}, webAction);
  }

  scrollToTop() {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    if (!this.props.needsConsentUpdate && !this.props.needsConsentLoading) {
      return <Redirect to="/data" />;
    }
    if (this.props.userConsents.length === 0) {
      return null;
    }

    const { t } = this.props;

    return (
      <div className="consent-flow" {...containerStyles} {...fullWidth}>
        <CardFlow progressColor={trout}>
          {/* First Card  */}
          <Card {...cardStyles} {...paddingLeftMedium} {...paddingRightMedium}>
            <div
              {...cardWrapperStyles}
              {...flex}
              {...column}
              {...alignItemsCenter}
            >
              <div {...marginTopMedium} {...imgWrapper}>
                <img src={dndSvg} alt="consent-first" {...imgStyles} />
              </div>

              <h2 {...font.h2} {...titleStyles}>
                {t('consentFlow.intro.title')}
              </h2>

              <p {...font.p3} {...bodyStyles} {...marginBottomMedium}>
                {t('consentFlow.intro.body')}
              </p>

              <NextButton
                {...marginBottomMedium}
                onClick={() => {
                  trackSE('input', 'click', 'lets_do_it', 'consent_intro');
                  this.scrollToTop();
                }}
              >
                {t('consentFlow.intro.confirmCTA')}
              </NextButton>
              <Link
                to="data"
                onClick={() => {
                  trackSE('input', 'click', 'not_right_now', 'consent_intro');
                }}
                className={linkStyles}
                {...font.utility2}
              >
                {t('consentFlow.intro.declineCTA')}
              </Link>
            </div>
          </Card>

          {/* Consent Cards  */
          this.props.userConsents.map(({ id, attributes }: any) => (
            <Card {...cardStyles} {...paddingMedium} key={id}>
              <div
                {...cardWrapperStyles}
                {...flex}
                {...column}
                {...alignItemsCenter}
              >
                <div {...marginTopMedium} {...imgWrapper}>
                  <img
                    src={`${env.apiHost}content/media-items/${attributes.mediaItemId}/download-image?width=600`}
                    alt={attributes.description}
                    {...imgStyles}
                  />
                </div>

                <h2 {...font.h2} {...titleStyles}>
                  {t(`consent.${attributes.code}.title`)}
                </h2>

                <p {...font.p3} {...bodyStyles} {...marginBottomMedium}>
                  {t(`consent.${attributes.code}.body`)}
                </p>

                <NextButton
                  {...marginBottomXsmall}
                  onClick={() => {
                    this.props.putUserConsent('ACCEPTED', id);
                    trackSE('input', 'click', 'accept', 'consent_request');
                    this.scrollToTop();
                  }}
                >
                  {t('consentFlow.cards.confirmCTA')}
                </NextButton>

                <NextButton
                  ghost={true}
                  {...marginBottomMedium}
                  onClick={() => {
                    this.props.putUserConsent('DECLINED', id);
                    trackSE('input', 'click', 'decline', 'consent_request');
                    this.scrollToTop();
                  }}
                >
                  {t('consentFlow.cards.declineCTA')}
                </NextButton>
              </div>
            </Card>
          ))}

          {/* last Card  */}
          <Card {...cardStyles} {...paddingLeftMedium} {...paddingRightMedium}>
            <div
              {...cardWrapperStyles}
              {...flex}
              {...column}
              {...alignItemsCenter}
            >
              <div {...marginTopMedium} {...imgWrapper}>
                <img src={lmSvg} alt="lets-meditate" {...imgStyles} />
              </div>

              <h2 {...font.h2} {...titleStyles}>
                {t('consentFlow.final.title')}
              </h2>

              <p {...font.p3} {...bodyStyles} {...marginBottomMedium}>
                {t('consentFlow.final.body')}
              </p>

              <NextButton
                {...marginBottomMedium}
                onClick={() => {
                  trackSE(
                    'input',
                    'click',
                    'explore_the_app',
                    'consent_confirmation',
                  );
                  this.goHome();
                }}
              >
                {t('consentFlow.final.confirmCTA')}
              </NextButton>

              <Link
                to="data"
                className={linkStyles}
                onClick={() => {
                  trackSE(
                    'input',
                    'click',
                    'review_privacy_settings',
                    'consent_confirmation',
                  );
                }}
                {...font.utility2}
              >
                {t('consentFlow.final.declineCTA')}
              </Link>
            </div>
          </Card>
        </CardFlow>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  ConsentFlowStateProps,
  ConsentFlowOwnProps,
  RootState
> = ({
  consents: { userConsents, needsConsentUpdate, needsConsentLoading },
}) => ({ userConsents, needsConsentUpdate, needsConsentLoading });

export const ConsentFlow = withNamespaces('translation')(
  withRouter(
    connect<
      ConsentFlowStateProps,
      ConsentFlowDispatchProps,
      ConsentFlowOwnProps,
      RootState
    >(
      mapStateToProps,
      ConsentsActions,
    )(ConsentFlowComponent) as any,
  ) as any,
);
