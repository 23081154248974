import {
  Consent,
  ConsentCodes,
  ConsentScopes,
} from '../../redux/modules/consents/consents';

const userPartnerConsent1: Consent = {
  id: 4,
  type: 'consents',
  attributes: {
    code: ConsentCodes.EX_SUBSCRIBER_DIRECT_CODE,
    name: 'Partner Consent Test',
    description: '',
    version: 1,
    active: true,
    title: 'Earn points with Virgin Pulse',
    body:
      'Share your Headspace activity data with Virgin Pulse to earn rewards for using the app. Virgin Pulse will be able to access:',
    mediaItemId: 7460,
    createdAt: '2018-05-17T22:59:56.000Z',
    updatedAt: '2018-05-17T22:59:56.000Z',
    scope: ConsentScopes.INDIVIDUAL,
    permissions: [
      'Your contact information including name and email.',
      'Whether you created an account.',
      'Whether you played any content, completed a course, or completed a check-in.',
      "If your program includes coaching, whether you've sent an initial chat to your coach.",
    ],
  },
};
export const userPartnerConsents = [userPartnerConsent1];
export const partnerConsentsSectionHelpText =
  'You can disconnect your account at any point by turning off this setting.';
