import { css } from '@headspace/hs-web-style-guide';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';
import {
  carrarra,
  comet,
  merino,
  silverChalice,
} from '@headspace/hs-web-style-guide/dist/colors';
import { utility4 } from '@headspace/hs-web-style-guide/dist/font';

const justifyContentCenter = css({ justifyContent: 'center' });
const alignItemsCenter = css({ alignItems: 'center' });
const flexStyle = css({ display: 'flex' });
const rowStyle = css({ flexDirection: 'row' });
const flex1 = css({ flex: 1 });
const flexBasis0 = css({ flexBasis: 0 });

export const tabBar = css(justifyContentCenter, alignItemsCenter, {
  backgroundColor: merino,
  color: silverChalice,
  overflow: 'scroll',
  [breakpoints.maxWidths.s]: {
    width: '100%',
  },
});

export const tabList = css(flexStyle, rowStyle, {
  listStyle: 'none',
  padding: 0,
  alignItems: 'flex-end',
});

export const tabBarUnderline = css(flexStyle, rowStyle, {
  height: 8,
});

export const tabUnderline = css(flex1, flexBasis0, {
  cursor: 'pointer',
  height: 8,
});

export const tabActive = css({
  color: comet,
});

export const tabUnderlineActive = css({
  backgroundColor: comet,
});

export const tabUnderlineInactive = css({
  backgroundColor: carrarra,
});

export const tab = css(justifyContentCenter, flex1, flexBasis0, utility4, {
  backgroundColor: merino,
  textAlign: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
});
