import * as React from 'react';
import {
  ConsentAttributes,
  ConsentsActions,
  EnactedConsentHash,
  EnactedIndividualConsent,
  IndividualConsent,
} from '../../redux/modules/consents/consents';
import { FieldWrapper } from '../FieldWrapper';
import { partnerConsentsSectionHelpText } from './constants';
import { Action } from 'redux';

interface PartnerConsentsSectionProps {
  options: string[];
  individualConsents: IndividualConsent[];
  enactedIndividualConsents: EnactedIndividualConsent[];
  putUserConsent: (status: string, consentId: number, action: Action) => void;
}

export const PartnerConsentsSection: React.FC<PartnerConsentsSectionProps> = ({
  options,
  individualConsents,
  enactedIndividualConsents,
  putUserConsent,
}) => {
  const enactedConsentHash: EnactedConsentHash = enactedIndividualConsents.reduce(
    (hash: EnactedConsentHash, consent: EnactedIndividualConsent) => {
      hash[consent.consentId] = consent.status;
      return hash;
    },
    {},
  );

  const syncUserConsentsAfterPut = (status: string, consentId: number) => {
    putUserConsent(status, consentId, ConsentsActions.getV1UserConsents());
  };

  return (
    <div>
      {individualConsents.map((consent, i) => {
        const helpText = partnerConsentsSectionHelpText;
        const partnerConsentBody = (
          <div>
            <span>{consent.body}</span>
            <ul>
              {(consent.permissions ?? []).map((item: string) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          </div>
        );
        const consentAttributes: ConsentAttributes = { ...consent };
        consentAttributes.body = partnerConsentBody;

        return (
          <FieldWrapper
            key={consent.title}
            enactedConsentHash={enactedConsentHash}
            id={consent.id}
            attributes={consentAttributes}
            options={options}
            putUserConsent={syncUserConsentsAfterPut}
            helpText={helpText}
          />
        );
      })}
    </div>
  );
};
