import * as React from 'react';
import { comet } from '@headspace/hs-web-style-guide/dist/colors';
import * as flexSet from '@headspace/hs-web-style-guide/dist/flex';
import { h6 } from '@headspace/hs-web-style-guide/dist/font';
import * as spacing from '@headspace/hs-web-style-guide/dist/spacing';
import { labelStyles, radioButtonStyle, radioWrapperStyles } from './styles';

const { flex } = flexSet.default;
const { paddingRightSmall } = spacing.default;

export interface RadioFieldProps {
  label: string;
  description?: string | React.ReactNode;
  options: string[];
  selectedInputIndex?: number;
  onInputSelect?: (idx: number) => void;
}

function createDescription(
  description?: string | React.ReactNode,
): React.ReactNode {
  if (description) {
    return (
      <div className="radioDescription" {...labelStyles(comet)}>
        {description}
      </div>
    );
  }
}

export function RadioField(props: RadioFieldProps): JSX.Element {
  const {
    label,
    description,
    options,
    selectedInputIndex,
    onInputSelect,
  } = props;
  const radioInputs = options.map((option, idx) => {
    const checked = selectedInputIndex === idx ? true : false;
    const onChange = (idx: number) => (event: Event) => {
      if (onInputSelect) onInputSelect(idx);
    };
    return (
      <input
        {...radioButtonStyle}
        type="radio"
        name={label}
        value={option}
        key={`${label}-${option}`}
        defaultChecked={checked}
        onClick={onChange(idx)}
      />
    );
  });
  return (
    <div {...radioWrapperStyles}>
      <div {...paddingRightSmall}>
        <label {...h6} htmlFor={label}>
          {label}
        </label>
        {createDescription(description)}
      </div>
      <div {...flex}>{radioInputs}</div>
    </div>
  );
}
