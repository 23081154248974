import * as React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RootState } from '../../redux/modules';
import {
  ConsentsActions,
  IndividualConsent,
  EnactedIndividualConsent,
} from '../../redux/modules/consents/consents';
import { RadioLabels } from '../RadioLabels';
import { PartnerConsentsSection } from '../PartnerConsentsSection/PartnerConsentsSection';
import { wrapperStyle } from './styles';
import i18next from 'i18next';
import { Action } from 'redux';

export interface PartnerDataSharingOwnProps {
  t: i18next.TFunction;
}

export interface PartnerDataSharingStateProps {
  individualConsents: IndividualConsent[];
  enactedIndividualConsents: EnactedIndividualConsent[];
}

export interface PartnerDataSharingDispatchProps {
  putUserConsent: (
    status: string,
    consentId: number,
    successDispatch?: Action,
  ) => void;
}

export type PartnerDataSharingProps = PartnerDataSharingOwnProps &
  PartnerDataSharingStateProps &
  PartnerDataSharingDispatchProps;

export const PartnerDataSharingComponent: React.FC<PartnerDataSharingProps> = ({
  t,
  enactedIndividualConsents,
  individualConsents,
  putUserConsent,
}) => {
  const options = [t('yes'), t('no')];

  return (
    <div {...wrapperStyle}>
      <RadioLabels options={options} />
      <PartnerConsentsSection
        options={options}
        enactedIndividualConsents={enactedIndividualConsents}
        individualConsents={individualConsents}
        putUserConsent={putUserConsent}
      />
    </div>
  );
};

const mapStateToProps: MapStateToProps<
  PartnerDataSharingStateProps,
  PartnerDataSharingOwnProps,
  RootState
> = ({ consents: { individualConsents, enactedIndividualConsents } }) => ({
  individualConsents,
  enactedIndividualConsents,
});

export const PartnerDataSharing = connect<
  PartnerDataSharingStateProps,
  PartnerDataSharingDispatchProps,
  PartnerDataSharingOwnProps,
  RootState
>(
  mapStateToProps,
  ConsentsActions,
)(PartnerDataSharingComponent) as any;
