import * as React from 'react';
import { Divider } from '@headspace/hs-web-component-library';
import {
  ConsentAttributes,
  ConsentStatus,
  EnactedConsentHash,
} from '../../redux/modules/consents/consents';
import { dividerStyle, rowContainer } from './styles';
import { trackSE } from '../../util/snowplow';
import { RadioField } from '../common/RadioField/RadioField';

export interface FieldWrapperProps {
  enactedConsentHash: EnactedConsentHash;
  id: number;
  attributes: ConsentAttributes;
  options: string[];
  putUserConsent: (status: string, consentId: number) => void;
  helpText?: string;
}

export class FieldWrapper extends React.Component<FieldWrapperProps> {
  constructor(props: FieldWrapperProps) {
    super(props);
    this.onOptionsSelect = this.onOptionsSelect.bind(this);
  }
  onOptionsSelect(index: number) {
    const { id: consentId, putUserConsent, attributes } = this.props;
    const options = [ConsentStatus.ACCEPTED, ConsentStatus.DECLINED];
    const consentStatus = options[index];
    trackSE('input', 'click', attributes.code, 'data_settings_nav');
    putUserConsent(consentStatus, consentId);
  }

  generateInputIndex() {
    const { enactedConsentHash, id } = this.props;
    let index;

    const status = enactedConsentHash[id];
    if (status) {
      switch (status) {
        case ConsentStatus.ACCEPTED:
          index = 0;
          break;
        case ConsentStatus.DECLINED:
          index = 1;
          break;
        default:
          break;
      }
    }
    return index;
  }

  public render() {
    const {
      enactedConsentHash,
      attributes,
      id,
      options,
      putUserConsent,
      helpText,
      ...props
    } = this.props;

    return (
      <div {...props}>
        <div {...rowContainer}>
          <RadioField
            label={attributes.title}
            description={attributes.body}
            options={options}
            selectedInputIndex={this.generateInputIndex()}
            onInputSelect={this.onOptionsSelect}
          />
          {helpText ? (
            <p data-testid="field-wrapper-help-text">{helpText}</p>
          ) : null}
        </div>
        <div {...dividerStyle}>
          <Divider />
        </div>
      </div>
    );
  }
}
