import { css } from '@headspace/hs-web-style-guide';
import * as utils from '@headspace/hs-web-style-guide/dist/utils';
import { comet } from '@headspace/hs-web-style-guide/dist/colors';
import * as font from '@headspace/hs-web-style-guide/dist/font';
import * as breakpoints from '@headspace/hs-web-style-guide/dist/breakpoints';
import * as cardBg from './images/card-bg.svg';

export const containerStyles = css({
  height: '100vh',
  color: '#4B5161',
});

export const linkStyles = css({
  textTransform: 'uppercase',
  display: 'block',
  textDecoration: 'none',
  color: comet,
  height: '50px',
  fontSize: '12px',
  [`@media (min-width: ${breakpoints.default.l}px)`]: {
    fontSize: '14px',
  },
});

export const cardStyles = css({
  backgroundSize: 'cover',
  backgroundImage: `url('${cardBg}')`,
  minHeight: '100%',
  boxSizing: 'border-box',
});

export const cardWrapperStyles = css({
  maxWidth: '900px',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const imgWrapper = css({
  maxWidth: '50%',
  minHeight: '150px',
});

export const imgStyles = css({
  width: '100%',
});

export const fullWidth = css(utils.default.fullWidth);

export const buttonStyles = css(font.utility2, {
  display: 'inline-block !important',
  padding: '0 !important',
  borderRadius: '4px !important',
  maxHeight: '50px',
  textAlign: 'center',
  fontSize: '12px',
  [`@media (min-width: ${breakpoints.default.l}px)`]: {
    width: '40%',
  },
});

export const titleStyles = css(font.h3, {
  fontWeight: 'normal',
  marginBottom: '0',
});

export const bodyStyles = css({
  [`@media (max-width: ${breakpoints.default.m}px)`]: {
    lineHeight: '28px',
  },
});

export const disableHoverState = css({
  ':hover': {
    background: 'inherit !important',
    color: 'inherit !important',
  },
});
