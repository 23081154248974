import apiClient from '../../lib/api-client';
import { UserSetting } from '../../model/user-setting';
import { constants, UserDataActions } from '../modules/user/userData';

interface packHash {
  [packName: string]: boolean;
}

const extractUserSettingValue = (resp: UserSetting.RootObject) => {
  const jsonObj = resp.data || resp.userSettings;
  let settingValue = '';
  if (jsonObj) {
    const userSettingValues = Object.values(jsonObj)[0];
    settingValue =
      userSettingValues && userSettingValues.attributes
        ? userSettingValues.attributes.settingValue
        : '';
  }
  return settingValue;
};

export namespace UserDataMiddleware {
  export const middleware = (store: any) => (next: any) => (action: any) => {
    next(action);
    const { dispatch } = store;
    // set up api client
    const {
      user: { jwt, userId },
    } = store.getState();
    const api = new apiClient(jwt);

    switch (action.type) {
      case constants.GET_USER:
        return api.get('user/users', { userId }).then(resp => {
          let user;
          if (resp.users) {
            user = resp.users[userId].attributes;
          }
          dispatch(UserDataActions.getUserSuccess(user));
        });
      case constants.GET_USER_COUNTRY_CODE:
        return api
          .get('user/user-settings', {
            userId,
            settingCategory: 'PROFILE',
            settingKey: 'COUNTRY_CODE',
          })
          .then(resp => {
            const countryCode = extractUserSettingValue(resp);
            dispatch(UserDataActions.getUserCountryCodeSuccess(countryCode));
          });
      case constants.GET_USER_LANGUAGE:
        return api
          .get('user/user-settings', {
            userId,
            settingCategory: 'I18N',
            settingKey: 'LANGUAGE_PREFERENCE',
          })
          .then(resp => {
            const countryCode = extractUserSettingValue(resp);
            dispatch(UserDataActions.getUserLanguageSuccess(countryCode));
          });

      case constants.POST_USER_COUNTRY_CODE:
        return api
          .post('user/user-settings/save', {
            userId,
            userSettings: [
              {
                settingCategory: 'PROFILE',
                settingKey: 'COUNTRY_CODE',
                settingValue: action.userCountryCode,
              },
            ],
          })
          .then(resp => {
            const countryCode = extractUserSettingValue(resp);
            dispatch(UserDataActions.postUserCountryCodeSuccess(countryCode));
          });
      case constants.GET_USER_PACK_COMPLETIONS:
        return api
          .get('content/user-activity-completions', {
            userId,
            removeDuplicates: true,
            includeKidsSessions: true,
          })
          .then(resp => {
            const userActivityCompletions = Object.values(
              resp.userActivityCompletions || {},
            );
            const completedPackHash: packHash = {};
            userActivityCompletions.forEach((completion: any) => {
              if (
                completion.attributes &&
                completion.attributes.activityGroupName
              ) {
                completedPackHash[
                  completion.attributes.activityGroupName
                ] = true;
              }
            });
            const completedPacks = Object.keys(completedPackHash);
            dispatch(
              UserDataActions.getUserPackCompletionsSuccess(completedPacks),
            );
          });
      case constants.GET_USER_APPBOY_DATA:
        return api
          .get('user/external/appboy/user', {
            userId,
          })
          .then(resp => {
            const appboyResponse = resp.appboyUsers || {};
            const doesUserDataExist = Object.values(appboyResponse).length > 0;
            const appboyData = doesUserDataExist
              ? (Object.values(appboyResponse)[0] as any).attributes
              : {};
            dispatch(UserDataActions.getUserAppboyDataSuccess(appboyData));
          });
    }
  };
}
