import { takeLatest, all, call } from 'redux-saga/effects';
import {
  constants,
  GetUserLanguageSuccessAction,
} from '../modules/user/userData';
import { i18n, defaultLanguage } from '../../i18n';

export function* changeLanguage(action: GetUserLanguageSuccessAction) {
  yield call(i18n.changeLanguage, action.userLanguage || defaultLanguage);
}

export function* watchGetUser() {
  yield takeLatest(constants.GET_USER_LANGUAGE_SUCCESS, changeLanguage);
}

export default function* rootSaga() {
  yield all([watchGetUser()]);
}
